"use client";

import { create } from "zustand";

import { SIDEBAR_WIDTH } from "layout/DashboardContent/DashboardContentContainer";

type State = {
  desktopNavIsOpen: boolean;
  mobileNavIsOpen: boolean;
  desktopTransform: string;
  desktopContainerWidth: string;
};

type Actions = {
  hideDesktopNav: () => void;
  toggleDesktopNav: () => void;
  toggleMobileNav: () => void;
  setMobileNav: (state: boolean) => void;
};

const desktopContainerWidth = (navIsOpen: boolean) =>
  navIsOpen ? `calc(100vw - ${SIDEBAR_WIDTH}px)` : "100vw";

export const useLayoutStore = create<State & Actions>((set) => ({
  desktopNavIsOpen: true,
  mobileNavIsOpen: false,
  desktopContainerWidth: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  desktopTransform: "",
  setMobileNav: (nv: boolean) => set((state) => ({ ...state, mobileNavIsOpen: nv })),
  hideDesktopNav: () =>
    set(() => ({
      desktopNavIsOpen: false,
      desktopContainerWidth: desktopContainerWidth(false),
      desktopTransform: ""
    })),
  toggleDesktopNav: () =>
    set((state) => ({
      desktopNavIsOpen: !state.desktopNavIsOpen,
      desktopContainerWidth: desktopContainerWidth(!state.desktopNavIsOpen),
      desktopTransform: !state.desktopNavIsOpen ? "" : `translateX(-${SIDEBAR_WIDTH}px)`
    })),
  toggleMobileNav: () => set((state) => ({ mobileNavIsOpen: !state.mobileNavIsOpen }))
}));
